.StarterContextRoot{
    height:260px;
    text-align:center;
    margin-top:90px;
    line-height:35px
}
.StarterContextRoot h1{
    font-weight: 700;
    color: #1c2b46;
    font-size:42px;
    font-family:'poppine';
    margin: 0;
    height: 55px;
}
.wordCarousel{
    font-size: 42px;
    font-weight: 100;
    color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.wordCarousel div{
    overflow: hidden;
    position: relative;
    height: 50px;
    padding-top: 10px;
    margin-top: -10px;
}
.wordCarousel div ul{
    padding: 0;
}
.wordCarousel div li{
    font-family: IRANSansWeb;
    color: #d40909 ;
    font-weight: 700;
    height: 45px;
    margin-bottom: 45px;
    display: block;
}

.flip2 { animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip3 { animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip4 { animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

@keyframes flip2 {
    0% { margin-top: -180px; }
    5% { margin-top: -90px;  }
    50% { margin-top: -90px; }
    55% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

@keyframes flip3 {
    0% { margin-top: -270px; }
    5% { margin-top: -180px; }
    33% { margin-top: -180px; }
    38% { margin-top: -90px; }
    66% { margin-top: -90px; }
    71% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

@keyframes flip4 {
    0% { margin-top: -360px; }
    5% { margin-top: -270px; }
    25% { margin-top: -270px; }
    30% { margin-top: -180px; }
    50% { margin-top: -180px; }
    55% { margin-top: -90px; }
    75% { margin-top: -90px; }
    80% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

.StarterContextRoot p{
    line-height: 40px;
    color: #8091a7;
    font-size:25px;
    font-family:system-ui;
    margin-top: 0;
}
