svg text {
    font-family: Lora;
    letter-spacing: 10px;
    stroke: #1976d2 ;
    font-size: 100px;
    font-weight: 700;
    stroke-width: 3;
    animation: textAnimate 5s infinite alternate;
}
@media screen and (max-width: 980px) {
    svg text {
        font-size: 70px;
    } 
}
@keyframes textAnimate {
    0% {
        stroke-dasharray: 0 50%;
        stroke-dashoffset:  20%;
        fill:#1976d2ad

    }
    100% {
        stroke-dasharray: 50% 0;
        stroke-dashoffstet: -20%;
        fill: hsla(189, 68%, 75%,0%)
    }
}
  