.sortMainDiv{
    width: 100% !important;
    min-height: 100px;
    display: flex;
    background-image: linear-gradient(180deg, #dee3ff, transparent);
    box-shadow: 0px 3px 4px 0px #00000061;
    margin: 0 !important;
}
.parentSortDiv{
    width: 100%;
    padding: 20px 0px 5px;
    text-align: center;
    font-size: 20px;
    font-family: poppine;
    background-image: linear-gradient(180deg, #9baaff, #dee3ff);
}