/* my profile */
.mainDiv {
    height: auto;
    width: auto;
  }
  
  .mainDiv form {
    width: 400px;
    height: auto;
    padding: 50px 20px;
    margin: auto;
    text-align: left;
  }
  
  .fieldDiv {
    display: block;
    padding: 5px 0;
    position: relative;
    margin-bottom: 15px;
  }
  
  .fieldDiv label, .fieldDiv input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
  }
  
  .fieldDiv label {
    font-family: poppine;
  }
  
  .fieldDiv input {
    width: 380px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    -webkit-box-shadow: 1px 1px 5px #adadad;
            box-shadow: 1px 1px 5px #adadad;
    color: #3f76c3;
    font-size: 13px;
    font-weight: bold;
    font-family: 'poppine-italic';
  }
  .changePassDiv{
    margin: 20px 0 0 0;
    padding: 10px;
    border: 1px dotted #5a9bdb;
    border-radius: 5px;
  }
  .passInput{
      width: 360px !important;
      margin-bottom: 10px;
  }
  
  .fieldDiv input:focus {
    border: none;
    -webkit-box-shadow: 1px 1px 10px #0072c3;
            box-shadow: 1px 1px 10px #0072c3;
    outline: none;
  }
  
  .fieldDiv input::-webkit-input-placeholder {
    font-family: poppine-italic;
    font-size: 11px;
    color: #7a7a7a;
  }
  
  .fieldDiv input:-ms-input-placeholder {
    font-family: poppine-italic;
    font-size: 11px;
    color: #7a7a7a;
  }
  
  .fieldDiv input::-ms-input-placeholder {
    font-family: poppine-italic;
    font-size: 11px;
    color: #7a7a7a;
  }
  
  .fieldDiv input::placeholder {
    font-family: poppine-italic;
    font-size: 11px;
    color: #7a7a7a;
  }
  .btnDiv {
    text-align: right;
    display: -ms-grid;
    display: grid;
  }
  
  .submitBtn {
    margin-top: 30px;
    padding: 8px 40px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 5px grey;
            box-shadow: 1px 1px 5px grey;
    border: none;
    color: white;
    background-color: #05a06c;
    font-family: poppine;
    cursor: pointer;
  }
  .logoutBtn {
    margin-top: 30px;
    padding: 8px 40px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 5px grey;
            box-shadow: 1px 1px 5px grey;
    border: none;
    color: white;
    background-color: #fd3f3f;
    font-family: poppine;
    cursor: pointer;
  }
  .passError{
    width: 100%;
    display: block !important;
    right: 0;
    bottom: -21px;
    text-align: right;
    font-family: 'poppine-italic';
    font-size: 13px;
    color: red;
  }
  @media screen and (max-width: 450px) {
    .passInput {
      width: 300px !important;
    }
  }
  
/* end of my profile */