.mainTextAnimateDiv{
    z-index: 99;
    position: absolute;
    top: 480px;
    padding-left: 200px;
}
.wrap{
    font-size: 25px;
    font-family: 'IRANSansWeb';
    color: white;
    background-color: #1976d2 !important;
    padding-right: 30px;
}
.lngText{
    font-size: 25px;
    font-family: 'IRANSansWeb';
    color: white;
    background-color: #1976d2 !important;
    padding-left: 30px;
}