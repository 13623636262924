/* main page */
@font-face {
    font-family: 'Lora';
    src: url("./assests/fonts/lora/Lora-Bold.ttf");
}
.container{
    padding: 0;
    /* width: 100vw; */
    max-width: 100vw;
    user-select: none;
    /* margin: auto; */
}
a{
    text-decoration: none;
}
/* end of main page */

/* panel page */
.profileListDiv{
    width: 100%;
    /* height: 30px; */
    background-color: #1976d2;
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
    /* position: fixed; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}
.profileListDiv a{
    color: white;
    font-family: 'poppine';
    font-size: 17px;
    width: 100%;
    height: 100%;
    transition: all .3s;
}
.profileListDiv a:hover{
    color: #efd7d2;
}
.activeLink{
    color: #ffab90 !important;
}
/* end of panel page */

/* admin page */
.adminBottomMenu{
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 25px 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: 17px;
    font-family: 'poppine';
    box-shadow: 0px 0px 17px 0px #00000061;
    background-color: white;
    z-index: 5;
}
.adminBottomMenu a{
    /* color: #3a3a3a; */
    color: black;
}
/* end of admin page */