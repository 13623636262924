/* send ads style */
#titleText {
    margin: 30px 0 0 0;
    text-align: center;
    color: #1976d2;
    font-size: 25px;
    font-family: 'poppine';
}

/* text field style */
.textFieldDiv{
    padding: 12px 0 ;
}

.errorsDiv{
    background-color: #ff000014;
    padding: 20px 10px;
    color: #ff0000;
    font-size: 17px;
    font-family: 'Roboto';
    line-height: 25px;
    margin-top: 20px;
}
.errorTitle{
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
}
#description{
    font-size: 20px;
}
#description.persian{
    direction: rtl;
    text-align: right;
    font-size: 20px;
    font-family: IRANSansWeb;
}


    